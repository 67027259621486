import { ElMessage } from 'element-plus'
const routes = [
    // 路由重定向
    { path: '/', redirect: '/home' },

    // 主页
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/home.vue'),
    },
    {
        path: '/firm',
        name: 'firm',
        component: () => import('@/views/home/firm.vue')
    },
    // 登录 
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue')
    },

    // 党建工作
    // 党建工作-党的建设
    {
        path: '/workConstruction',
        name: 'workConstruction',
        component: () => import('../views/work/workConstruction.vue')
    },
    // 党建工作-党建活动
    {
        path: '/workActivity',
        name: 'workActivity',
        component: () => import('../views/work/workActivity.vue')
    },
    // 党建工作-详细内容
    {
        path: '/showWorkInfos',
        name: 'showWorkInfos',
        component: () => import('../views/work/showWorkInfos.vue')
    },

    // 党建工作-信息展示
    {
        path: '/showWorkInfos',
        name: 'showWorkInfos',
        component: () => import('../views/work/showWorkInfos.vue')
    },

    // 会员服务
    // 会员服务-会员风采
    {
        path: "/memberModel",
        name: 'memberModel',
        component: () => import('../views/member/memberModel.vue')
    },
    // 会员服务-会员动态
    {
        path: "/memberDynamic",
        name: 'memberDynamic',
        component: () => import('../views/member/memberDynamic.vue')
    },
    // 会员服务-服务清单
    {
        path: "/serviceList",
        name: 'serviceList',
        component: () => import('../views/member/serviceList.vue')
    },
    // 会员服务-详细内容
    {
        path: "/showMemberInfos",
        name: 'showMemberInfos',
        component: () => import('../views/member/showMemberInfos.vue')
    },

    // 行业动态
    // 行业动态-通知公告
    {
        path: '/notice',
        name: 'notice',
        component: () => import('../views/sector/notice.vue')
    },
    // 行业动态-协会动态
    {
        path: '/associationDynamic',
        name: 'associationDynamic',
        component: () => import('../views/sector/associationDynamic.vue')
    },
    // 行业动态-政策法规
    {
        path: '/dealRegulations',
        name: 'dealRegulations',
        component: () => import('../views/sector/dealRegulations.vue')
    },
    // 行业动态-行业资讯
    {
        path: '/industryNews',
        name: 'industryNews',
        component: () => import('../views/sector/industryNews.vue')
    },
    // 行业动态-详细内容
    {
        path: '/showSectorInfos',
        name: 'showSectorInfos',
        component: () => import('../views/sector/showSectorInfos.vue')
    },

    // 诚信企业
    {
        path: '/goodFirms',
        name: 'goodFirms',
        component: () => import('../views/creditworthiness/goodFirms.vue')
    },

    // 会员单位
    {
        path: '/memberUnit',
        name: 'memberUnit',
        component: () => import('../views/home/memberUnit.vue')
    },

    // 关于我们
    // 协会简介
    {
        path: '/aboutIntroduce',
        name: 'aboutIntroduce',
        component: () => import('../views/about/aboutIntroduce.vue'),
    },
    // 协会领导
    {
        path: '/aboutLeadership',
        name: 'aboutLeadership',
        component: () => import('../views/about/aboutLeadership.vue'),
    },
    // 加入我们
    {
        path: '/joinUs',
        name: 'joinUs',
        component: () => import('../views/about/joinUs.vue'),
    },
    // 联系我们
    {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/about/contactUs.vue'),
    },

    // 用户中心
    {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/user.vue'),
        meta: { isAuth: true, title: '用户中心' },
        children: [
            // 用户中心：首页
            {
                path: 'userHome',
                name: 'userHome',
                component: () => import('../views/user/userHome.vue')
            },
            // 用户中心：申请成为会员单位
            {
                path: 'applicationVIP',
                name: 'applicationVIP',
                component: () => import('../views/user/applicationVIP.vue')
            },
            // 用户中心：用户设置
            {
                path: 'userSetting',
                name: 'userSetting',
                component: () => import('../views/user/userSetting.vue')
            },
            // 用户中心：会员层级调整
            {
                path: 'tiering',
                name: 'tiering',
                component: () => import('../views/user/membershipManage/tiering.vue')
            },
            // 用户中心：申请退会
            {
                path: 'withdrawalRequest',
                name: 'withdrawalRequest',
                component: () => import('../views/user/membershipManage/withdrawalRequest.vue')
            },
            // 用户中心：联系人变更
            {
                path: 'changingContacts',
                name: 'changingContacts',
                component: () => import('../views/user/membershipManage/changingContacts.vue')
            },
            // 用户中心：会费转账凭证上传
            {
                path: 'subscriptionCredentials',
                name: 'subscriptionCredentials',
                component: () => import('../views/user/subscriptionManage/subscriptionCredentials.vue')
            },
            // 用户中心：会费减免申请
            {
                path: 'subscriptionRemission',
                name: 'subscriptionRemission',
                component: () => import('../views/user/subscriptionManage/subscriptionRemission.vue')
            },
            // 用户中心：会议通知
            {
                path: 'noticeMeeting',
                name: 'noticeMeeting',
                component: () => import('../views/user/meetingsManagement/noticeMeeting.vue')
            },
            // 用户中心：报送回执
            {
                path: 'returnReceipt',
                name: 'returnReceipt',
                component: () => import('../views/user/meetingsManagement/returnReceipt.vue')
            },
            // 用户中心：发布项目
            {
                path: 'publishProject',
                name: 'publishProject',
                component: () => import('../views/user/projectPool/publishProject.vue')
            },
            // 用户中心：申请项目
            {
                path: 'applicationProject',
                name: 'applicationProject',
                component: () => import('../views/user/projectPool/applicationProject.vue')
            },
            // 用户中心：已参与项目
            {
                path: 'participateProject',
                name: 'participateProject',
                component: () => import('../views/user/projectPool/participateProject.vue')
            },
            // 用户中心：我的项目
            {
                path: 'myProject',
                name: 'myProject',
                component: () => import('../views/user/projectPool/myProject.vue')
            },
            {
                path: 'suggestion',
                name: 'suggestion',
                component: () => import('../views/user/suggestion/index.vue')
            }
        ],
        beforeEnter: (to, from, next) => {
            if (to.meta.isAuth) {
                if (localStorage.getItem('token')) {
                    next();
                } else {
                    ElMessage({
                        message: '请先登录',
                        type: 'warning'
                    })
                    next('/login');
                }
            } else {
                next();
            }
        }
    },
    // 申报流程
    {
        path: '/declarationProcess',
        name: 'declarationProcess',
        component: () => import('../views/declarationProcess/declarationProcess.vue'),
        alias:'/index/index'
    },
];
export default routes;