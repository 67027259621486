import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store/index'
import router from './router/index'
import axios from 'axios'
import VueAxios from 'vue-axios'

const app =  createApp(App)

router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
})

app.use(ElementPlus)
app.use(store)
app.use(router)
app.use(VueAxios,axios);
app.mount('#app')
