<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
html,body{
  margin: 0;
  padding: 0;
  // background-color: rgb(245, 245, 245);
  background-color: #ffffff;
}
a{
  color: #000000;
  text-decoration: none;
}
</style>
